import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout/Layout.js"
import { Row, TrainingRow } from "../components/row/Row.js"
import { Title, Subtitle, HeadingIcon} from "../components/title/Title.js"
import { ButtonContainer, PrimaryButton } from "../components/button/Button.js"
import { TextMedium } from "../components/text/Text.js"
import Seo from "../components/seo"
import { Section } from "../components/section/Section.js"
import { Column50, Toolkitwrapper } from "../components/column/Column.js"
import featuredImage from '../static/undraw_Connecting_Teams_8ntu.png'
import { DwdAsset } from "../components/dwdAsset/DwdAsset"
import BlogSubscribe from "../components/blogSubscribe/BlogSubscribe.js"
import Subscribe from '../components/subscribe/Subscribe'
import { faChess, faHandshake, faRocket, faVideo, faClock } from "@fortawesome/free-solid-svg-icons"
import { SnippetLink } from "../components/link/Link.js"


function ContentTemplates({ children, isCentered, ...rest }) {

    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
      allContentfulTemplate{
        edges {
          node {
            id
            cover {
              gatsbyImageData(width:200)
              id
              title
              file {
                url
              }
            }
            excerpt {
              excerpt
              childMarkdownRemark {
                excerpt(truncate: true, pruneLength: 300)
              }
            }
            price
            childContentfulTemplateTitleTextNode {
              title
            }
            url
            gumroadUrl
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Content Strategy Toolkits and Templates" 
       description="Get up and running in no time with these toolkits & templates."
       image={featuredImage}
       featuredImage={featuredImage} 
      />
      
      <BlogSubscribe/>

      <Section isCentered>
       <Row isCentered>
          <Subtitle isPrimary subtitle="Toolkits" />
        </Row>
        <Row isCentered>
          <Toolkitwrapper isCentered>
            <HeadingIcon icon={faChess} isCentered heading="The Content Strategist’s Success Kit" />
            <TextMedium textMedium="This kit includes 7 templates and video guides and a website wireframe kit. Everything you need to get started with your content strategy." />
            <SnippetLink
                href="https://socontent.gumroad.com/l/content-strategist-starting-kit"
                anchortext="Get the toolkit"
                />
          </Toolkitwrapper>
          <Toolkitwrapper isCentered>
            <HeadingIcon 
              icon={faVideo} 
              isCentered
              heading="The Content Marketer’s Starting Kit"
            />
            <TextMedium textMedium="This kit includes all the templates and video guides you need to get started with content marketing for a B2B SaaS company. Get your content right from day one." />
            <SnippetLink
              href="/content-strategy-templates/"
              anchortext="Coming soon"
            />
          </Toolkitwrapper>
          </Row>
          <Row>
          <Toolkitwrapper isCentered>
            <HeadingIcon 
              icon={faRocket} 
              isCentered
              heading="The Growth Marketer’s Starting Kit"
            />
            <TextMedium textMedium="This kit includes all the templates and video guides you need to build a content system that drives predictable results. Get started with paid campaigns and SEO." />
            <SnippetLink
              href="/content-strategy-templates/"
              anchortext="Coming soon"
            />
          </Toolkitwrapper>
          <Toolkitwrapper isCentered>
            <HeadingIcon 
              icon={faHandshake}  
              isCentered 
              heading="The Partner Marketer’s Starting Kit" />
            <TextMedium textMedium="This kit includes a Partner Strategy deck, a Partner Program setup video, and an example of Co-marketing deck, to get you up and running in no time." />
            <SnippetLink 
              href="https://socontent.gumroad.com/l/partner-marketer-starting-kit"
              anchortext="Get the toolkit"
            />
          </Toolkitwrapper>
        </Row>
        <Row isCentered>
          <ButtonContainer>
            <PrimaryButton
              buttonText="See all toolkits"
              buttonLink="https://socontent.gumroad.com/"
            />
          </ButtonContainer>
        </Row>
      </Section>

      <Section isCentered>
        <Row isCentered>
          <Title isPrimary title="Templates" />
        </Row>
        <Row>
          {data.allContentfulTemplate.edges.map(({ node }) => (
            <Column50 key={node.id} >
              <DwdAsset
                snippetTitle={node.childContentfulTemplateTitleTextNode.title}
                snippetText={node.excerpt.childMarkdownRemark.excerpt}
                imageAlt={node.childContentfulTemplateTitleTextNode.title}
                href={node.url}
                buyhref={node.gumroadUrl}
                anchorText="See details"
                // buyanchorText="Buy template"
              />
            </Column50>
          ))}
        </Row>
      </Section>
      <Section isCentered>    
        <Row isCentered>
        <Subscribe />
        </Row>
      </Section>
    </Layout>
  )
}

export default ContentTemplates
