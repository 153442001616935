import styled from "styled-components"
import { colors } from "../../styles/colors.js"
import { sizes } from "../../styles/sizes"
import { fontSizes } from "../../styles/typography"
import { StyledH3 } from "../title/style"

export const SnippetWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: ${props => (props.isCentered ? "center" : "flex-start")};
  padding: ${sizes.sizeXXL} ${sizes.sizeXXXXL};
  border: 2px solid #C6B1BD;
  margin-bottom: ${sizes.sizeXL};
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  background-color: ${props =>
    props.isLight ? `${colors.colorTextLightest}` : `${colors.colorWhite}`};
  color: ${colors.colorTextDark};
  width: auto;
`

export const NewSnippetWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: ${props => (props.isCentered ? "center" : "flex-start")};
  align-items: ${props => (props.isCentered ? "center" : "flex-start")};
  margin-bottom: ${sizes.sizeXL};
  margin-left: auto;
  margin-right: auto;
  border-radius: 2rem;
  background-color: ${props =>
    props.isLight ? `${colors.colorTextLightest}` : `${colors.colorWhite}`};
  color: ${colors.colorTextDark};
  max-width: 360px;
`

export const SnippetTitle = styled(StyledH3)`
  color: ${colors.colorTextDark};
  font-size: ${fontSizes.fontL};
  margin-bottom: 20px;
  line-height: 24px;
`

export const SnippetText = styled.p`
  font-size: ${fontSizes.fontM};
  margin-bottom: 20px;
  line-height: ${fontSizes.fontL};
  width: 100%;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
`

export const SnippetImage = styled.img`
  object-fit: cover;
  object-position: center center;
  height: 300px;
  width: 100%;
  border-radius: 2rem;
  margin-bottom: 20px;
`

export const AuthorWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0;
  width: 100%;
`

export const LinkWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0;
  width: 100%;
`