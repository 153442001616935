import React, { useState } from "react"
import { TextMedium } from "../text/Text.js"
import {
  SubscribeRow,
  SubscribeField,
  StyledSubscribe,
  StyledSubscribeButton,
  SubscribeButtonContainer,
} from "./style.js"
import { Column100 } from "../column/Column"
import { Heading } from "../title/Title.js"

const SubscribeButton = ({ buttonText, buttonLink }) => (
  <StyledSubscribeButton to={buttonLink}>{buttonText}</StyledSubscribeButton>
)

const Subscribe = (...props) => {
  const [firstName, setFirstname] = useState("")
  const [email, setEmail] = useState("")

  const handleSubmit = event => {
    event.preventDefault()

    var xhr = new XMLHttpRequest()
    var url =
      `https://api.hsforms.com/submissions/v3/integration/submit/25880547/bc0d2329-6e3a-4955-8e1e-4109dfb57bc1`

    var data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "email",
          value: email,
        },
        {
          name: "firstname",
          value: firstName,
        },
      ],
      context: {
        // "hutk": ':hutk', // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        pageUri: window.location.pathname,
        pageName: document.title,
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text:
            "I agree to allow Example Company to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text:
                "I agree to receive marketing communications from Example Company.",
            },
          ],
        },
      },
    }

    var final_data = JSON.stringify(data)

    xhr.open("POST", url)
    xhr.setRequestHeader("Content-Type", "application/json")

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        window.location = "https://www.schoolofcontent.net/subscribe-success/" 
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        window.location = "https://www.schoolofcontent.net/404/" 
      }
    }

    xhr.send(final_data)
  }

  return (
    <StyledSubscribe
      name="subscribe"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      method="POST"
      redirect="https://www.schoolofcontent.net/subscribe-success/"
      onSubmit={handleSubmit}
      isLight={props.isLight}
    >
      <SubscribeRow isCentered>
        <Column100 isCentered>
          <Heading heading="Learn from our experience" />
          <TextMedium
            textMedium="Receive actionable content strategy & marketing articles
            directly in your inbox by subscribing to our newsletter"
          />
          <SubscribeButtonContainer>
            <SubscribeField
              type="email"
              id="email"
              name="email"
              placeholder="Your email address*"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required="required"
              isWide
            />
            <SubscribeField
              type="text"
              id="firstName"
              name="firstName"
              placeholder="Your name*"
              value={firstName}
              onChange={e => setFirstname(e.target.value)}
            />
            <SubscribeButton
              buttonText="Join the list"
              buttonLink="/subscribe-success/"
            />
          </SubscribeButtonContainer>
        </Column100>
      </SubscribeRow>
    </StyledSubscribe>
  )
}

export default Subscribe
