import React from "react"
import {
  NewSnippetWrapper,
  SnippetWrapper,
  SnippetText,
  SnippetTitle,
  AuthorWrapper,
  LinkWrapper,
  SnippetImage,
} from "./style.js"
import { SnippetLink, Link } from "../link/Link"

export const DwdAsset = ({ ...props }) => (
  <SnippetWrapper isLight={props.isLight} isCentered={props.isCentered}>
    <SnippetTitle>{props.snippetTitle} </SnippetTitle>
    <SnippetText>{props.snippetText}</SnippetText>
    <LinkWrapper>
    <SnippetLink
        isCentered={props.isCentered}
        anchortext={props.anchorText}
        href={props.href}
        isPrimary={props.isPrimary}
    />
    {/* <SnippetLink
        isCentered={props.isCentered}
        anchortext={props.buyanchorText}
        href={props.buyhref}
        isPrimary={props.isPrimary}
      /> */}
      </LinkWrapper>
  </SnippetWrapper>
)

export const NewDwdAsset = ({ ...props }) => (
  <NewSnippetWrapper isLight={props.isLight} isCentered={props.isCentered}>
    <SnippetImage src={props.snippetImage} alt={props.imageAlt} />
    <SnippetTitle>{props.snippetTitle} </SnippetTitle>
    <SnippetText>{props.snippetText}</SnippetText>
      <SnippetLink
      isCentered={props.isCentered}
      anchortext={props.anchorText}
      href={props.href}
      isPrimary={props.isPrimary}
    />
  </NewSnippetWrapper>
)
